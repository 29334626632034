import { Factory } from 'app/app.factory';
import { Observable } from 'rxjs';
import { AuthService } from 'app/common/services/UserAuth/AuthService';
import { RecordStatus, AgreementStaus,PageType } from 'app/common/enum/type.enum';
import { DatePipe } from '@angular/common';
import { Injectable } from "@angular/core";

@Injectable()
export class idleTeamQueueService {
    constructor(private factory: Factory, private auth: AuthService, private datepipe: DatePipe) { }
    public service: any=this.factory.getService();
    public actionSearchRecordData: string;
    public searchDataProperties: string;
    public pageArgs: string;
    public user: any;
  
    getPage(sort: string, order: string, page: number, pageSize: number, filterString: string): Observable<PageResult> {
  
      this.user = this.auth.getUserInforFn();
      var UTCnowTime = new Date();
      var reject = new Date(UTCnowTime).getTime() - 60*24*60*60*1000;
      var UTCnow = this.datepipe.transform(reject,"MM/dd/yyyy hh:mm:ss aa","UTC");             
      this.actionSearchRecordData ="q=(RecordStatus:\"" + RecordStatus.AwaitingUserAction + "\")" + filterString + " and type:[Document] and not container:[type:[Document Backup]] and not AgreementStatus:\"" + AgreementStaus.Deleted + "\" and RejectDate <\""+UTCnow + "\"";                
      this.searchDataProperties = "properties=TotalResults%2Cnumber%2CSupplierName%2CAgreementName%2CAgreementType%2CPrimaryCountry%2CKeyCategory%2CCategoryVal%2CAgreementEndDate%2CAgreementAttachedNotSignedfinalized%2CIncompleteAgreement%2CIncorrectMetadataField%2COther%2CIncorrectAgreementAttached%2CRejectDate%2CParentOrSupportingDocument%2CRecordAllHolds%2CSupplierID%2CPrimaryGU%2CPrimaryRegion";
      this.pageArgs = this.bindPageArgs(sort, order, page, pageSize);
  
      return this.service.RecordList(this.actionSearchRecordData, this.searchDataProperties + this.pageArgs + '&PageType=' + PageType.RequiredActions).then(res => {
  
        var result = new PageResult();
        var array = [];
        result.Total = res.TotalResults;
  
        if (result.Total > 0) {
          res.Results.forEach(element => {
            var temp = new RecordResult();
            if (this.checkUndefined(element.RecordNumber)) {
              temp.number = element.RecordNumber.Value;
            }
            if (this.checkUndefined(element.Uri)) {
              temp.Uri = element.Uri;
            }
            temp.legalHold = element.RecordAllHolds.Value != '' ? true : false;
            if (this.checkUndefined(element.Fields.AgreementName)) {
              temp.typedTitle = element.Fields.AgreementName.Value;
            }
            if (this.checkUndefined(element.Fields)) {
              temp.AgreementType = this.bindValue(element.Fields.AgreementType);
              temp.SupplierID = this.bindValue(element.Fields.SupplierID);
              temp.PrimaryCountry = this.bindValue(element.Fields.PrimaryCountry);
              temp.PrimaryGU=this.bindValue(element.Fields.PrimaryGU);
              temp.PrimaryRegion=this.bindValue(element.Fields.PrimaryRegion);
              temp.KeyCategory = this.bindValue(element.Fields.KeyCategory);
              temp.CategoryVal = this.bindValue(element.Fields.CategoryVal);
              temp.SupplierName = this.bindValue(element.Fields.SupplierName);
              temp.ParentOrSupportingDocument = this.bindValue(element.Fields.ParentOrSupportingDocument);
            }
            if (this.checkUndefined(element.Fields.AgreementEndDate)) {
              temp.AgreementEndDate = this.datepipe.transform(element.Fields.AgreementEndDate.DateTime, 'dd-MMM-yyyy');
            }
            let actionList = [];
            if (this.checkUndefined(element.Fields.IncorrectMetadataField)) {
              if (element.Fields.IncorrectMetadataField.Value) {
                actionList.push('Incorrect metadata fields');
              }
            }
            if (this.checkUndefined(element.Fields.IncorrectAgreementAttached)) {
              if (element.Fields.IncorrectAgreementAttached.Value) {
                actionList.push('Incorrect agreement attached');
              }
            }
            if (this.checkUndefined(element.Fields.AgreementAttachedNotSignedfinalized)) {
              if (element.Fields.AgreementAttachedNotSignedfinalized.Value) {
                actionList.push('Agreement attached not signed/finalized');
              }
            }
            if (this.checkUndefined(element.Fields.IncompleteAgreement)) {
              if (element.Fields.IncompleteAgreement.Value) {
                actionList.push('Incomplete agreement');
              }
            }
            if (this.checkUndefined(element.Fields.Other)) {
              if (element.Fields.Other.Value) {
                actionList.push('Other');
              }
            }
            temp.Action = actionList.join(',');
  
            if (this.checkUndefined(element.Fields.RejectDate)) {
              if (element.Fields.RejectDate.DateTime.indexOf('0001-01-01T00') > -1) {
                temp.RejectDate = ' ';
              } else {
                temp.RejectDate = this.datepipe.transform(element.Fields.RejectDate.DateTime, 'dd-MMM-yyyy');
              }
            }
  
            array.push(temp);
          });
          result.Data = array;
        }
        return result;
      });
    }
  
    async getTotal(searchQ: string,searchProperties: string){
      var result= 0;
      await this.service.RecordListCount(searchQ,searchProperties).then(res => {
        result=res.TotalResults;
      }, err => {
        console.log(err);
      });
      return result;
    }
    bindPageArgs(sort: string, order: string, page: number, pageSize: number) {
      var result = "&pageSize=" + pageSize + "&start=" + ((page * pageSize) + 1);
      if (typeof (sort) != "undefined") {
        result += "&sortBy=" + sort;
      }
      if (order == 'desc') {
        result += "-";
      }
  
      return result;
    }
    bindValue(field: any) {
      if (typeof (field) != 'undefined') {
        return field.Value;
      }
      return "";
    }
    checkUndefined(field: any) {
      if (typeof (field) != 'undefined') {
        return true;
      }
      return false;
    }
    
  }
  
export class PageResult {
    public Total: number;
    public Data: RecordResult[];
  }
  
  export class RecordResult {
    number: string;
    Uri: number;
    legalHold: boolean;
    SupplierName: string;
    typedTitle: string;
    AgreementType: string;
    SupplierID: string;
    PrimaryCountry: string;
    PrimaryGU: string;
    PrimaryRegion: string;
    KeyCategory: string;
    CategoryVal: string;
    AgreementEndDate: string;
    RejectDate: string;
    Action: string;
    ParentOrSupportingDocument: string;
  }